import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogTitle, Grid, Hidden, Typography} from "@mui/material";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Layout from '../../templates/layout';
import * as actions from '../../actions';
import BasePageContent from "../../components/utils/BasePageContent";
import OrderRenewalDetails from "../../components/orderRenewals/OrderRenewalDetails";
import Paginator from "../../components/Paginator";
import {OrdersListCards, OrdersListTable} from "../../components/user/orders/list";
import ScheduleAccordion from "../../components/orderRenewals/ScheduleAccordion";


const mapStateToProps = (state) => ({
  renewal: state.getOrderRenewal,
  orders: state.getOrdersForOrderRenewal,
  schedule: state.getScheduleForOrderRenewal,
});

const OrderRenewal = ({code, renewal, actions, orders, schedule}) => {
  const [loading, setLoading] = useState(true);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  useEffect(() => {
    actions.getOrderRenewal(code);
  }, [actions, code])

  useEffect(() => {
    if (orders) setLoading(false);
  }, [orders])

  useEffect(() => {
    if (renewal) {
      actions.getOrdersForOrderRenewal(code);
      if (renewal.data?.status === 'active') {
        actions.getScheduleForOrderRenewal(code);
      }

      setCancelDialogOpen(false);
    }
  }, [renewal, actions, code])

  const handleCancel = () => actions.cancelOrderRenewal(renewal.data.code);

  const openCancelDialog = () => setCancelDialogOpen(true);
  const closeCancelDialog = () => setCancelDialogOpen(false);

  return <Layout>
    <BasePageContent loading={loading}>
      {renewal?.data && (
        <>
          <OrderRenewalDetails renewal={renewal?.data}>
            {renewal.data.status === 'active' && (
              <Grid container sx={{p: 2}}>
                <Grid item xs={12} textAlign='center'>
                  <Button
                    variant='outlined'
                    onClick={openCancelDialog}
                  >
                    Odwołaj powtarzanie płatności
                  </Button>
                </Grid>
              </Grid>
            )}
          </OrderRenewalDetails>
          <Dialog open={cancelDialogOpen} onClose={closeCancelDialog}>
            <DialogTitle>Odwołaj powtarzanie płatności</DialogTitle>
            <DialogActions>
              <Button
                variant='outlined'
                onClick={closeCancelDialog}
              >
                Zamknij
              </Button>
              <Button
                variant='contained'
                disableElevation
                onClick={handleCancel}
              >
                Odwołaj
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Grid item xs={12} my={4}>
        <Paginator
          resource={orders}
          getResource={pagination => actions.getOrdersForOrderRenewal(code, pagination)}
          render={(orders, renderPagination) => (
            <>
              {orders && (<>
                <Box textAlign='center'>
                  <Typography variant='h5' paragraph>
                    Lista zleceń
                  </Typography>
                </Box>
                <Hidden mdDown>
                  <OrdersListTable orders={orders}>
                    {renderPagination()}
                  </OrdersListTable>
                </Hidden>
                <Hidden mdUp>
                  <OrdersListCards orders={orders}>
                    {renderPagination()}
                  </OrdersListCards>
                </Hidden>
              </>)}
            </>
          )}
        />
      </Grid>
      {(renewal?.data.status === 'active' && schedule) && (
        <Box>
          <Typography variant='h5' paragraph textAlign='center'>
            Harmonogram tworzenia płatności
          </Typography>
          <ScheduleAccordion schedule={schedule} defaultOpen={false} showRenewalLink={false}/>
        </Box>
      )}
    </BasePageContent>
  </Layout>
}

export const Head = () => <title>Szczegóły płatności cyklicznej | Zapłatomat</title>;

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRenewal);
